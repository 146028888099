export function slugify(text) {
    return text
        .toString() // Cast to string (optional)
        .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
        .toLowerCase() // Convert the string to lowercase letters
        .trim() // Remove whitespace from both sides of a string (optional)
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w\-]+/g, '') // Remove all non-word chars
        .replace(/\-\-+/g, '-'); // Replace multiple - with single -
}
export function dateOfBirthFromSsn(ssn) {
    var century = '20';
    if (ssn.substring(6, 7) === '-') {
        if (parseInt(ssn.substring(4, 6)) > 23)
            century = '19';
    }
    var dateOfBirth = century +
        ssn.substring(4, 6) +
        '-' +
        ssn.substring(2, 4) +
        '-' +
        ssn.substring(0, 2);
    return new Date(dateOfBirth);
}
export function parseDateOfBirthFromSsn(ssn) {
    var century = '20';
    if (ssn.substring(6, 7) === '-') {
        if (parseInt(ssn.substring(4, 6)) > 23)
            century = '19';
    }
    var dateOfBirth = century +
        ssn.substring(4, 6) +
        '-' +
        ssn.substring(2, 4) +
        '-' +
        ssn.substring(0, 2);
    return Date.parse(dateOfBirth);
}
export function ageFromDateOfBirth(dob, startTime) {
    var date = startTime ? startTime : new Date();
    var month_diff = date.getTime() - dob.getTime();
    //convert the calculated difference in date format
    var age_dt = new Date(month_diff);
    // extract year from date
    var year = age_dt.getUTCFullYear();
    // calculate the age of the user
    var age = Math.abs(year - 1970);
    return age;
}
export function ageFromSsn(ssn, startTime) {
    var date = startTime ? startTime : new Date();
    var dob = dateOfBirthFromSsn(ssn);
    var age = ageFromDateOfBirth(dob, startTime);
    return age;
}
