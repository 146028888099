var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslate } from '../translation/Translate';
var SelectZone = function (_a) {
    var zones = _a.zones, selectedZone = _a.selectedZone, setSelectedZone = _a.setSelectedZone, style = _a.style;
    var t = useTranslate();
    // Select default zone value
    useEffect(function () {
        if (selectedZone)
            return;
        setSelectedZone(zones[0]._id);
    }, []);
    return (_jsxs("div", __assign({ className: style }, { children: [_jsx("label", { children: _jsx("div", __assign({ className: "text-left" }, { children: t('zone.zone') })) }), _jsx("select", __assign({ value: selectedZone, onChange: function (e) { return setSelectedZone(e.target.value); } }, { children: zones.map(function (zone) { return (_jsx("option", __assign({ value: zone._id, selected: selectedZone === zone._id }, { children: zone.name }), zone._id)); }) }))] }), 'zone'));
};
export default SelectZone;
