var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { createInvitation } from '../../actions/invitationActions';
import Invitation from '../../components/common/shareLicense/Invitation';
import Header from '../../components/therapist/Header';
import { useTranslate } from '../../components/translation/Translate';
import { getFreeAndReducedZoneLicenses, getTherapistFreeAndReducedZoneLicenses, } from '../../actions/licenseActions';
import { createExtensionPeriod } from '../../actions/periodActions';
import useUser from '../../hooks/useUser';
import { getTherapistZones } from '../../actions/zoneActions';
import { uniqBy } from 'lodash';
import { THERAPY_MODULE_TYPES } from '../../components/common/enums';
import usePromise from '../../hooks/usePromise';
import { doesUserHaveLicense } from '../../actions/userActions';
var ShareLicense = function () {
    var _a;
    var t = useTranslate();
    var user = useUser();
    var _b = __read(useState([]), 2), reducedLicenses = _b[0], setReducedLicenses = _b[1];
    var _c = __read(useState(null), 2), invitationData = _c[0], setInvitationData = _c[1];
    var _d = __read(useState(''), 2), moduleId = _d[0], setModuleId = _d[1];
    var _e = __read(useState(14), 2), validUntil = _e[0], setValidUntil = _e[1];
    var _f = __read(useState(''), 2), ssn = _f[0], setSsn = _f[1];
    var _g = __read(useState(''), 2), email = _g[0], setEmail = _g[1];
    var _h = __read(useState(''), 2), phone = _h[0], setPhone = _h[1];
    var _j = __read(useState(''), 2), firstName = _j[0], setFirstName = _j[1];
    var _k = __read(useState(''), 2), lastName = _k[0], setLastName = _k[1];
    var _l = __read(useState('fi'), 2), selectedLanguage = _l[0], setSelectedLanguage = _l[1];
    var _m = __read(useState(''), 2), selectedZone = _m[0], setSelectedZone = _m[1];
    var _o = __read(useState([]), 2), zones = _o[0], setZones = _o[1];
    var _p = __read(useState(null), 2), errorMessage = _p[0], setErrorMessage = _p[1];
    var _q = __read(useState(''), 2), firstLicenseId = _q[0], setFirstLicenseId = _q[1];
    var _r = __read(useState(false), 2), extensionPeriodCreatedSuccess = _r[0], setExtensionPeriodCreatedSuccess = _r[1];
    var _s = __read(usePromise(function () { return doesUserHaveLicense({
        ssn: ssn,
        module: moduleId
    }, user.session); }, [ssn, moduleId]), 2), userHasLicense = _s[0], userHasLicenseLoading = _s[1];
    var isUserTherapist = !!((_a = user.userinfo) === null || _a === void 0 ? void 0 : _a.therapistInfo);
    var getFreeLicenses = isUserTherapist
        ? getTherapistFreeAndReducedZoneLicenses
        : getFreeAndReducedZoneLicenses;
    function getFreeAndReducedLicenses() {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getFreeLicenses(user.session)];
                    case 1:
                        data = _a.sent();
                        if (data) {
                            setReducedLicenses(data);
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    function getUserZones() {
        return __awaiter(this, void 0, void 0, function () {
            var zonesData, evaluationZones, therapyZones, allZones;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getTherapistZones(user.session)];
                    case 1:
                        zonesData = _a.sent();
                        if (zonesData) {
                            evaluationZones = zonesData.evaluationZones, therapyZones = zonesData.therapyZones;
                            allZones = uniqBy(__spreadArray(__spreadArray([], __read(evaluationZones), false), __read(therapyZones), false), '_id');
                            setZones(allZones);
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    function submitInvitation() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var reducedLicense, zone, invitation, response, invitationData_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        setErrorMessage(null);
                        reducedLicense = reducedLicenses.find(function (license) { return license.firstLicenseId === firstLicenseId; });
                        if (!(user.userinfo && reducedLicense)) {
                            return [2 /*return*/];
                        }
                        zone = isUserTherapist
                            ? selectedZone
                            : (_a = user.userinfo.licenseControllerInfo) === null || _a === void 0 ? void 0 : _a.zone;
                        invitation = {
                            license: reducedLicense.firstLicenseId,
                            licenseKey: reducedLicense.firstLicenseKey,
                            sender: (_b = user.userinfo) === null || _b === void 0 ? void 0 : _b.userId,
                            validUntil: validUntil,
                            moduleId: moduleId,
                            zone: zone,
                            email: email,
                            ssn: ssn,
                            phone: phone,
                            firstName: firstName,
                            lastName: lastName,
                            language: selectedLanguage,
                        };
                        if (!((userHasLicense === null || userHasLicense === void 0 ? void 0 : userHasLicense.hasLicense) === "true" && moduleId === THERAPY_MODULE_TYPES.PALVELUTARPEEN_ARVIO)) return [3 /*break*/, 1];
                        // Can't create extension period for evaluation
                        setErrorMessage(t('licenses.evaluation_error_message'));
                        return [3 /*break*/, 5];
                    case 1:
                        if (!((userHasLicense === null || userHasLicense === void 0 ? void 0 : userHasLicense.hasLicense) === "true")) return [3 /*break*/, 3];
                        return [4 /*yield*/, createExtensionPeriod(__assign(__assign({}, invitation), { extensionLicense: userHasLicense !== undefined }), user.session)];
                    case 2:
                        response = _c.sent();
                        if (response) {
                            setExtensionPeriodCreatedSuccess(true);
                            getFreeAndReducedLicenses();
                        }
                        else {
                            setErrorMessage(t('licenses.general_license_error_message'));
                        }
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, createInvitation(invitation, user.session)];
                    case 4:
                        invitationData_1 = _c.sent();
                        if (invitationData_1) {
                            setInvitationData(invitationData_1);
                            getFreeAndReducedLicenses();
                        }
                        _c.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    }
    // On init
    useEffect(function () {
        getFreeAndReducedLicenses();
        if (isUserTherapist) {
            getUserZones();
        }
    }, []);
    var extensionLicense = "not-checked";
    if (userHasLicenseLoading) {
        extensionLicense = "loading";
    }
    else {
        extensionLicense = userHasLicense === null || userHasLicense === void 0 ? void 0 : userHasLicense.hasLicense;
    }
    return (_jsxs(React.Fragment, { children: [_jsx(Header, { title: t('nav.share_license') }), _jsx("main", __assign({ className: "px-8 py-4 flex flex-col gap-6" }, { children: _jsx("div", __assign({ className: "bg-white px-8 py-4 rounded-md shadow-md w-full max-w-3xl m-auto" }, { children: _jsx(Invitation, { submitInvitation: submitInvitation, reducedLicenses: reducedLicenses, invitationData: invitationData, moduleId: moduleId, setModuleId: setModuleId, validUntil: validUntil, setValidUntil: setValidUntil, setSsn: setSsn, setEmail: setEmail, setPhone: setPhone, firstName: firstName, setFirstName: setFirstName, lastName: lastName, setLastName: setLastName, selectedLanguage: selectedLanguage, setSelectedLanguage: setSelectedLanguage, extensionLicense: extensionLicense, errorMessage: errorMessage, extensionPeriodCreatedSuccess: extensionPeriodCreatedSuccess, setFirstLicenseId: setFirstLicenseId, zones: zones, selectedZone: selectedZone, setSelectedZone: setSelectedZone, isSentByTherapist: isUserTherapist }) })) }))] }));
};
export default ShareLicense;
