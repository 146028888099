var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { apiPost, apiGet, apiPostJson, apiPut, apiDelete } from './apiActions';
import { parseDateOfBirthFromSsn } from '../functions/conversions';
export function activateUser(activeData, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPut('/api/activate-user', activeData, session)];
                case 2:
                    response = _a.sent();
                    if (response && response.status && response.status === 204) {
                        return [2 /*return*/, true];
                    }
                    return [2 /*return*/, false];
                case 3:
                    error_1 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, false];
                case 4: return [2 /*return*/, false];
            }
        });
    });
}
export function deactivateUser(activeData, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPut('/api/deactivate-user', activeData, session)];
                case 2:
                    response = _a.sent();
                    if (response && response.status && response.status === 204) {
                        return [2 /*return*/, true];
                    }
                    return [2 /*return*/, false];
                case 3:
                    error_2 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, false];
                case 4: return [2 /*return*/, false];
            }
        });
    });
}
export function updateSelectedUser(updateUserInfoRequest, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPut('/api/modify-user', updateUserInfoRequest, session)];
                case 2:
                    response = _a.sent();
                    if (response && response.status && response.status === 204) {
                        return [2 /*return*/, true];
                    }
                    else {
                        return [2 /*return*/, false];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, false];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function getUsers(searchFilter, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPost('/api/get-users', searchFilter, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        return [2 /*return*/, response.data];
                    }
                    return [2 /*return*/, null];
                case 3:
                    error_4 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function getUser(userInfoId, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(userInfoId && session)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiGet('/api/get-userinfo/' + userInfoId, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        return [2 /*return*/, response.data];
                    }
                    return [2 /*return*/, null];
                case 3:
                    error_5 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function getUserinfo(session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiGet('/api/users', session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        // TODO: Validate data
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_6 = _a.sent();
                    // TODO: show errors in console only in dev/staging
                    // TODO: show generic error messages to user
                    //console.error(error);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function createNewCustomer(createNewCustomerRequest) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, apiPostJson('/api/customers/', createNewCustomerRequest)];
                case 1:
                    response = _a.sent();
                    if (response && response.status && response.status === 201) {
                        return [2 /*return*/, true];
                    }
                    else {
                        return [2 /*return*/, false];
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_7 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, false];
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function createCustomerAndStartExtensionPeriod(createNewCustomerRequest) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_8;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, apiPostJson('/api/customers/start-extension-period', createNewCustomerRequest)];
                case 1:
                    response = _a.sent();
                    if (response && response.status && response.status === 201) {
                        return [2 /*return*/, true];
                    }
                    else {
                        return [2 /*return*/, false];
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_8 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, false];
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function updateCustomer(userInfo, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_9;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, apiPut('/api/customers/', userInfo, session)];
                case 1:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        // TODO: Validate data
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_9 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function forgetPassword(email) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_10;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, apiPost('/api/users/forget-password', { email: email })];
                case 1:
                    response = _a.sent();
                    if (response && response.status && response.status === 204) {
                        return [2 /*return*/, true];
                    }
                    else {
                        return [2 /*return*/, false];
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_10 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, false];
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function updatePassword(passwordData, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_11;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, apiPut('/api/users/change-password', passwordData, session)];
                case 1:
                    response = _a.sent();
                    if (response && response.status && response.status === 204) {
                        return [2 /*return*/, true];
                    }
                    else {
                        return [2 /*return*/, false];
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_11 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, false];
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function createLicenceController(newLicenceController, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_12;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPost('/api/create-new-licence-controller', newLicenceController, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        return [2 /*return*/, response.data];
                    }
                    return [2 /*return*/, null];
                case 3:
                    error_12 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function createAdmin(newAdmin, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_13;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPost('/api/create-new-admin', newAdmin, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        return [2 /*return*/, response.data];
                    }
                    return [2 /*return*/, null];
                case 3:
                    error_13 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function createTherapist(newTherapist, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_14;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPostJson('/api/therapist/create-new-therapist', newTherapist, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        return [2 /*return*/, response.data];
                    }
                    return [2 /*return*/, null];
                case 3:
                    error_14 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function changePasswordWithLink(passwordData, passwordResetToken) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_15;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, apiPut("/api/users/change-password-with-link?id=".concat(passwordResetToken), passwordData)];
                case 1:
                    response = _a.sent();
                    if (response && response.status && response.status === 204) {
                        return [2 /*return*/, true];
                    }
                    else {
                        return [2 /*return*/, false];
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_15 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, false];
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function updateTherapistInfo(info, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_16;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPostJson('/api/therapistprofile/', info, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        // TODO: Validate data
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_16 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function updateTherapistAvailability(patientTarget, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_17;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPut('/api/therapistprofile/therapist-availability', patientTarget, session)];
                case 2:
                    response = _a.sent();
                    if (response && response.status && response.status === 204) {
                        return [2 /*return*/, true];
                    }
                    else {
                        return [2 /*return*/, false];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_17 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, false];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function updateTherapistAbsenceMessage(absenceMessage, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_18;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPut('/api/therapistprofile/absence-message', absenceMessage, session)];
                case 2:
                    response = _a.sent();
                    if (response && response.status && response.status === 204) {
                        return [2 /*return*/, true];
                    }
                    else {
                        return [2 /*return*/, false];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_18 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, false];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function deleteAbsence(absenceMessage, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_19;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPut('/api/therapistprofile/deleteabsencemessage', absenceMessage, session)];
                case 2:
                    response = _a.sent();
                    if (response && response.status && response.status === 204) {
                        return [2 /*return*/, true];
                    }
                    else {
                        return [2 /*return*/, false];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_19 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, false];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function updateDefaultMessages(defaultMessage, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_20;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPut('/api/therapist/default-messages', defaultMessage, session)];
                case 2:
                    response = _a.sent();
                    if (response && response.status && response.status === 204) {
                        return [2 /*return*/, true];
                    }
                    else {
                        return [2 /*return*/, false];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_20 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, false];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function deleteDefaultMessage(messageId, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_21;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(session && messageId)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiDelete('/api/therapist/default-messages/' + messageId, session)];
                case 2:
                    response = _a.sent();
                    if (response && response.status && response.status === 204) {
                        return [2 /*return*/, true];
                    }
                    else {
                        return [2 /*return*/, false];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_21 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, false];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function doesUserHaveLicense(payload, session) {
    return __awaiter(this, void 0, void 0, function () {
        var allFieldsFilled, ssnIsValid;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    allFieldsFilled = payload.ssn && payload.module;
                    ssnIsValid = payload.ssn && !isNaN(parseDateOfBirthFromSsn(payload.ssn));
                    if (!allFieldsFilled) {
                        return [2 /*return*/, { hasLicense: 'not-checked' }];
                    }
                    if (!ssnIsValid) {
                        return [2 /*return*/, { hasLicense: 'not-checked' }];
                    }
                    return [4 /*yield*/, apiPost('/api/licenses/user-has-license', {
                            ssn: payload.ssn,
                            module: payload.module
                        }, session)];
                case 1: return [4 /*yield*/, (_a.sent()).data];
                case 2: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
